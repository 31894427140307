import React from "react";
import { Wrapper, Title } from "./style";

const Intro = (props) => (
  <Wrapper {...props}>
    <Title>
      Hi, my name is Anvil Palamattam. <br></br>
      I'm an <span>application modernization engineer</span> at <span>Google</span>.
    </Title>
  </Wrapper>
);

export default Intro;
